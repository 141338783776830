// https://github.com/floriangaechter/gatsby-plugin-linkedin-insight
// https://github.com/andrezimpel/gatsby-plugin-gdpr-cookies - original
// https://github.com/chssch/gatsby-plugin-gdpr-cookies - linkedin fork

import { initialiseAndTrackLinkedIn } from './';

export const onClientEntry = (_, pluginOptions) => {

    window.gatsbyPluginGDPRCookiesLinkedInAdded = false;

    if (!pluginOptions.cookieName) pluginOptions.cookieName = 'gatsby-gdpr-linkedin';
    if (!pluginOptions.environments) pluginOptions.environments = [ 'production' ];

    window.gatsbyPluginGDPRCookiesLinkedInOptions = pluginOptions;

};

export const onRouteUpdate = ({ location }) => initialiseAndTrackLinkedIn(location);