module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[],"defaults":{"placeholder":"blurred","quality":100}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Integrum ESG","short_name":"IntegrumESG","start_url":"/","background_color":"#132D53","theme_color":"#132D53","icon":"src/images/icon-transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6da58e893a455c9ac0130c3ba099104e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-KY3R4P263K"},"environments":["production"]},
    },{
      plugin: require('../plugins/gatsby-plugin-gdpr-cookies-linkedin/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"3446770","environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
