export const initialiseAndTrackLinkedIn = location => {
    const options = window.gatsbyPluginGDPRCookiesLinkedInOptions;

    if (isEnvironmentValid(options.environments)) {
        if (location === undefined || location === null) console.error("Please provide a reach router location to the initializeAndTrack function.");
        else if (getCookie(options.cookieName) === 'true' && validLinkedInId(options)) addLinkedIn(options);
    };
};

const addLinkedIn = ({ trackingId }) => {
    if (window.gatsbyPluginGDPRCookiesLinkedInAdded) return;

    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [  ];
    window._linkedin_data_partner_ids.push(trackingId);

    (() => {
        var s = document.getElementsByTagName("script")[0]; 
        var b = document.createElement("script"); 
        b.type = "text/javascript";
        b.async = true; 
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);}
    )();

    window.gatsbyPluginGDPRCookiesLinkedInAdded = true;
};

const validLinkedInId = options => options.trackingId && options.trackingId.trim() !== '';

const isEnvironmentValid = environments => {
    const currentEnvironment = process.env.ENV || process.env.NODE_ENV || `development`;
    return environments.includes(currentEnvironment);
};

const getCookie = name => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};